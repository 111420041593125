import { useContext, useMemo, useState } from "react";
import { AuthContext } from "../contexts/authContext";
import { getPortfolio, getTradeHistory } from "../libs/api";
import {
  PieChart,
  Pie,
  Cell,
} from "recharts";
import { COLORS } from "../helpers/colors";
import { PriceContext } from "../contexts/priceContext";
import { Box, Flex, Text } from "rebass";
import { Table } from "./Table";
import { Loading } from "./loading";
import Button from "@material-ui/core/Button";

export const Portfolio = ({
  selectedExchange,
}: {
  selectedExchange: string;
}) => {
  const [showMath, setShowMath] = useState(false);
  const auth = useContext(AuthContext);
  const priceContext: { [key: string]: any } = useContext(PriceContext);
  const [portfolio, setPortfolio] = useState<{
    [exchange: string]: {
      [key: string]: {
        current_value: number;
        amount: number;
        average_price: number;
        total_spend: number;
        profit_loss_dollars: number;
        profit_loss_percent: number;
      };
    };
  }>();
  const [isFetching, setIsFetching] = useState(false);
  const [trades, setTrades] = useState<{
    [key: string]: {
      stats: { [key: string]: any };
      trades: any[];
    };
  }>();

  useMemo(async () => {
    if (Object.entries(auth?.sessionInfo as {}).length === 0) {
      return;
    }

    if (!portfolio && !isFetching) {
      setIsFetching(true);
      const portfolio = await getPortfolio(selectedExchange);

      if (portfolio) {
        setPortfolio(portfolio);
      }

      const result = await getTradeHistory({ tickers: Object.keys(portfolio["coinbase"]), selectedExchange });
      if (result) {
        setTrades(result);
      }


      setIsFetching(false);
    }
  }, [auth?.sessionInfo, portfolio, isFetching, selectedExchange]);

  if (!auth) {
    return null;
  }

  if (!portfolio) {
    return null;
  }

  if (!priceContext) {
    return null;
  }

  if (!selectedExchange) {
    return null;
  }

  if (!priceContext.prices[selectedExchange]) {
    return null;
  }

  let data =
    portfolio[selectedExchange] &&
    Object.entries(portfolio[selectedExchange])
      ?.map(([ticker, stats]) => ({ ...stats, value: stats.current_value, name: ticker }))
      .sort((a, b) => (a.current_value < b.current_value ? 1 : -1));

  if (!data) {
    return <Box>No portfolio yet - it'll show up once a limit order executes.</Box>;
  }

  if (isFetching) {
    return <Loading />
  }
  const totalSpend = data
    .map((coin) => coin.total_spend)
    .reduce((prevValue, currentValue) => (prevValue += currentValue));
  const totalValue = data.map(coin => coin.value).reduce((prevValue, currentValue) => prevValue += currentValue);
  const totalProfitOrLossInDollars = data
    .map((coin) => coin.profit_loss_dollars)
    .reduce((prevValue, currentValue) => (prevValue += currentValue));
  const totalProfitOrLossInPercent =
    (totalProfitOrLossInDollars / totalSpend) * 100;

  const startPriceByCoin: any = {}
  const totalSpendByCoin: { [key: string]: number } = {}
  const amountBoughtByCoin: { [key: string]: number } = {}
  const currentValueByCoin: { [key: string]: number } = {}

  if (trades) {
    Object.keys(trades).map(key => {
      const tradesList = trades[key].trades;
      const price = tradesList.length > 0 ? tradesList[0].average_filled_price : 0;
      startPriceByCoin[key] = parseFloat(price);
    })

    data.map((coin) => totalSpendByCoin[coin.name] = coin.total_spend)


    Object.entries(totalSpendByCoin).map(([key, value]) => amountBoughtByCoin[key] = value / startPriceByCoin[key])


    Object.entries(amountBoughtByCoin).map(([key, value]) => currentValueByCoin[key] = priceContext.prices["coinbase"][key] * value)
  }

  return (
    <Box
      sx={{
        backgroundColor: "rgb(0 0 0 / 16%)",
        width: "100%",
      }}
      p={4}
    >
      <Box margin="0 auto" width={["100%", "800px"]}>
        <Text as="h2">Portfolio</Text>
        <Text mb="2">
          This is pulled from your trade history, not your active balance on the
          exchange.
        </Text>
        <Flex flexDirection={["column"]}>
          <Box margin="0 auto">
            <Flex flexDirection={["row"]}>
              <Box mr="4" mt="4">
                <Text as="h3">Current Value</Text>
                <Text as="h1">${Math.round(totalValue).toLocaleString()}</Text>
              </Box>
              <Box mr="4" mt="4">
                <Text as="h3">Profit or Loss</Text>
                <Text as="h1">
                  ${Math.round(totalProfitOrLossInDollars).toLocaleString()}
                </Text>
              </Box>
              <Box mr="4" mt="4">
                <Text as="h1" mt="1.5rem">
                  {Math.round(totalProfitOrLossInPercent).toLocaleString()}%
                </Text>
              </Box>
            </Flex>
          </Box>
          <Box margin="0 auto">
            {/* <ResponsiveContainer width="100%" height={400}> */}
            <PieChart width={400} height={400}>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                outerRadius={100}
                dataKey="value"
                label={(entry) => entry.name}
              >
                {data.map((entry) => (
                  <Cell
                    key={`cell-${entry.name}`}
                    fill={
                      COLORS[entry.name.toLowerCase().replace('-', '')] ? `${COLORS[entry.name.toLowerCase().replace('-', '')]}90` : "#bab3b3"
                    }
                  />
                ))}
              </Pie>
            </PieChart>
            {/* </ResponsiveContainer> */}
          </Box>
          {data && (
            <Box mt={[0, 5]} ml="auto" mr="auto">
              <Table
                headers={[
                  "ticker",
                  "amount",
                  "average price",
                  "current value",
                  "total spend",
                  "profit/loss",
                  "%",
                ]}
                rows={data
                  .filter((row) => row.amount > 0)
                  .map((row) => {
                    const ticker = row.name;
                    const amountOfCoins = row.amount;
                    const averagePrice = row.average_price;
                    const currentValue = row.current_value;
                    const totalSpend = row.total_spend;
                    const profitOrLossInDollars = row.profit_loss_dollars;
                    const profitOrLossInPercent = row.profit_loss_percent;

                    return [
                      ticker,
                      amountOfCoins.toFixed(8),
                      `$${averagePrice.toLocaleString()}`,
                      `$${currentValue.toLocaleString()}`,
                      `$${totalSpend.toLocaleString()}`,
                      `$${profitOrLossInDollars.toLocaleString()}`,
                      `${profitOrLossInPercent.toFixed()}%`,
                    ];
                  })}
              />
            </Box>
          )}

          <Box mt="3rem">
            <h2>Comparision against lump-sum</h2>
            <Box ml="auto" mr="auto">
              {Object.values(data).map(coin => {
                const dipRobotData = data.find((item) => item.name === coin.name);
                if (!dipRobotData!.total_spend) {
                  return;
                }
                const profitLossDipRobot = dipRobotData!.current_value - dipRobotData!.total_spend;
                
                const profitLossLumpSum = currentValueByCoin[coin.name] - totalSpendByCoin[coin.name];

                const performanceInDollars = profitLossDipRobot - profitLossLumpSum;

                // Percentage performance calculations
                const lumpSumPerformance = (profitLossLumpSum / totalSpendByCoin[coin.name]) * 100;
                const dipRobotPerformance = (profitLossDipRobot / totalSpendByCoin[coin.name]) * 100;
                const performanceDifference = dipRobotPerformance - lumpSumPerformance;

                

                return (
                  <>
                    <h3>{coin.name}</h3>
                    {performanceInDollars > 0
                      ? (`DipRobot beats lump-sum by $${performanceInDollars.toFixed(2)} (${performanceDifference.toFixed(2)}%)`)
                      : (`Lump-sum wins by ${Math.abs(performanceDifference).toFixed(2)}%`)}
                    <Box>
                      {showMath && (
                        <Box p={1} style={{border: "1px solid"}}>
                          <p>Start price: ${startPriceByCoin[coin.name]}</p>
                          <p>Total spend: ${totalSpendByCoin[coin.name]}</p>
                          <p>Amount bought: {amountBoughtByCoin[coin.name]} coins</p>
                          <p>USD value: lump sum: ${currentValueByCoin[coin.name].toFixed(2)} vs DipRobot: ${dipRobotData!.current_value.toFixed(2)}</p>
                          <p>Profit or loss of lump sum: ${profitLossLumpSum.toFixed(2)}</p>
                          <p>Profit or loss of DipRobot: ${profitLossDipRobot.toFixed(2)}</p>
                          <p>USD comparison, DipRobot minus lump sum: ${performanceInDollars.toFixed(2)}</p>
                          <p>Percentage comparison, DipRobot: {dipRobotPerformance.toFixed(2)}% vs Lump-sum: {lumpSumPerformance.toFixed(2)}%</p>

                        </Box>
                      )}
                    </Box>
                  </>
                );
              })}
              <Box mt={3}>
              <Button onClick={() => setShowMath(!showMath)} variant="outlined">{showMath ? "Hide" : "Show"} math</Button>
              </Box>
            </Box>

          </Box>
        </Flex>
      </Box>
    </Box>
  );
};
